<template>
  <div class="control">
    <div class="tags has-addons">
      <span class="tag pl-1 pr-1" v-bind:class="[bgColor]">
        <span class="tag-dot"> • </span>
      </span>
      <span class="tag pl-0" v-bind:class="[bgColor]">{{ title }}</span>
    </div>
  </div>
</template>

<script>
import { filterSelects } from "@/home/TenderListFull/TenderListData";

export default {
  components: {},
  name: "TndStatus",
  props: {
    statusId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    item() {
      return filterSelects.status[this.statusId];
    },
    title() {
      return this.item ? this.item.title : "";
    },
    bgColor() {
      return this.item ? this.item.bgColor : "";
    },
  },
  methods: {},
};
</script>

<style lang="scss">
</style>
