<template>
  <div class="tnd-file">
    <div class="file-info">
      <p class="file-info__name">{{ file.file_name }}</p>
      <p class="file-info__date">Дата загрузки: {{ createdDate }}</p>
      <p class="file-info__size">Размер: {{ file.size }} кБ</p>
    </div>
    <div class="file-actions">
      <slot name="actions">
        <button class="file-actions__button" @click="$emit('action', file)" v-if="action === 'delete'">
          <span>Удалить</span><trash-icon />
        </button>
        <a 
          class="file-actions__button" 
          :href="file.link" 
          @click.prevent="$emit('action', file)"  
          v-if="action === 'download'">
          <span>Скачать</span><download-icon/>
        </a>
      </slot>
      
    </div>
  </div>
</template>

<script>
import DownloadIcon from '../icons/DownloadIcon.vue'
import TrashIcon from '../icons/TrashIcon.vue';
export default {
  components: { TrashIcon,  DownloadIcon },
  name: "TndFile",
  props: {
    file: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      default: ''
    }
  },
  computed: {
    createdDate() {
      return this.$moment(this.file.dt_create).format('DD MMMM YYYY, HH:mm')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/variables.scss';
.tnd-file {
  position: relative;
  background: #ffffff;
  box-shadow: 3px 5px 20px rgba(175, 175, 175, 0.15);
  border-radius: 5px;
  padding: 24px 34px 24px 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 26px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(94.43deg, #a2165b 0%, #5a004b 100%);
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
  }

  .file-info {
    &__name {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #323232;
      margin-bottom: 5px;
    }

    &__date,
    &__size {
      font-style: normal;
      font-weight: 350;
      font-size: 12px;
      line-height: 14px;
      color: $purple-bright;
    }
  }

  .file-actions {
    &__button {
      display: flex;
      align-items: center;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #6f6f6f;
        margin-right: 7px;
        background: none;
      }
    }
  }
}
</style>
