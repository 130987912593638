import { render, staticRenderFns } from "./TndPrePrice.vue?vue&type=template&id=60a6bbba&scoped=true&"
import script from "./TndPrePrice.vue?vue&type=script&lang=js&"
export * from "./TndPrePrice.vue?vue&type=script&lang=js&"
import style0 from "./TndPrePrice.vue?vue&type=style&index=0&id=60a6bbba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a6bbba",
  null
  
)

export default component.exports