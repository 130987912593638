<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M2.86719 4.06641H15.1333L13.9221 14.9679C13.8805 15.3431 13.7021 15.6898 13.4209 15.9416C13.1397 16.1934 12.7754 16.3326 12.398 16.3326H5.60254C5.22507 16.3326 4.86087 16.1934 4.57965 15.9416C4.29844 15.6898 4.11999 15.3431 4.07847 14.9679L2.86719 4.06641Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.43151 1.87933C5.55552 1.61635 5.75174 1.39405 5.99729 1.23835C6.24285 1.08266 6.5276 0.999997 6.81836 1H11.182C11.4729 0.999851 11.7578 1.08245 12.0035 1.23815C12.2492 1.39385 12.4456 1.61623 12.5696 1.87933L13.6 4.06654H4.40039L5.43151 1.87933Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33398 4.06641H16.6667" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.46777 7.90039V11.7336" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5332 7.90039V11.7336" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TrashIcon',
  props: {
    color: {
      type: String,
      default: '#6F6F6F'
    }
  }
}
</script>

<style>

</style>