<template>
  <div class="breadcrumbs">
    <div class="container pl-5 pr-5 pt-2 pb-2 is-flex is-align-items-center">
      <HomeIcon class="mr-1" />
      <span v-for="(item, i) of breadcrumbItems" :key="i">
        <DashIcon class="mr-1" />
        <span class="mr-1">{{ item.title }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import HomeIcon from "@/components/icons/HomeIcon.vue";
import DashIcon from "@/components/icons/DashIcon.vue";

export default {
  name: "BreadCrumbs",
  components: {
    HomeIcon,
    DashIcon,
  },
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  background: #fafafa;
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  font-size: 0.88rem;
  font-weight: 300;
}

.mr-1-5 {
}
</style>
