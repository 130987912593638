<template>
  <div class="tnd-table">
    <b-table
    :data="data"
    class="custom-table border-top"
    :bordered="true"
    icon-pack="fas"
    :mobile-cards="false"
  >
    <template #empty>
      <div class="has-text-centered">Нет записей</div>
    </template>
    <b-table-column
      v-for="(column, index) in columns"
      :key="index"
    >
      <template #header>
        <div
          class="table-header-title"
          :style="{ 'width': column.width + 'px' }"
        >
          {{ column.label }}
        </div>
      </template>
      <template v-slot="props">
        <slot :column="column" :props="props">
        </slot>
        <div v-if="!column.type">
          {{props.row[column.field]}}
        </div>
      </template>
    </b-table-column>
  </b-table>
  </div>
  
</template>

<script>
export default {
    name: 'TndTable',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
      tableValues: {}
    }),
    methods: {
    },
    watch: {
      tableValues(newVal) {
        this.$emit('tableChange', newVal)
      }
    }
    
}
</script>

<style lang="scss">
.tnd-table {
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  .table.is-bordered {
    border-radius: 10px;
  }
  .table-header-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #6f6f6f;
    text-transform: uppercase;
  }

  .row-title {
    color: #323232;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
  }

  .row-description {
    color: #6F6F6F;
    font-weight: 350;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>