<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.375 7.875H14.0625M3.9375 14.0625H1.125V5.0625H16.875V14.0625H14.0625H3.9375ZM3.9375 10.6875V16.875H14.0625V10.6875H3.9375ZM14.0625 5.0625V1.125H3.9375V5.0625H14.0625Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'PrinterIcon',
  props: {
    color: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

<style>

</style>