<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6663 1.66797L1.33301 11.0013L10.6663 20.3346" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.33301 11H22.6663" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BackArrowIcon',
  props: {
    color: {
      type: String,
      default: '#E4E4E4'
    }
  }
}
</script>

<style>

</style>